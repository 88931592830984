import { type ReactNode, forwardRef } from 'react';
import type {
  RadioGroupProps as RACRadioGroupProps,
  ValidationResult,
} from 'react-aria-components';
import { Label, RadioGroup as RACRadioGroup } from 'react-aria-components';

import { InfoOutline } from '../../icons/info-outline.js';
import { Button } from '../button/index.js';
import { Tooltip, TooltipTrigger } from '../tooltip/index.js';
import {
  bodyStyle,
  childrenRecipe,
  hintStyles,
  radioGroupRecipe,
} from './radio-button.css.js';

export interface RadioGroupProps extends RACRadioGroupProps {
  orientation?: 'vertical' | 'horizontal';
  label?: string;
  children: ReactNode;
  hint?: string;
  description?: string;
  helperMessage?: string;
  errorMessage?: string | ((validation: ValidationResult) => string) | null;
  message?: ReactNode;
}

export const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  (props: RadioGroupProps, ref) => {
    const {
      orientation,
      label,
      children,
      isDisabled,
      hint,
      message,
      ...restProps
    } = props;

    return (
      <RACRadioGroup
        className={radioGroupRecipe({ isDisabled })}
        isDisabled={isDisabled}
        ref={ref}
        {...restProps}
      >
        <div className={bodyStyle}>
          <Label>{label}</Label>
          {hint ?
            <TooltipTrigger delay={0}>
              <Button
                aria-label="Tooltip Trigger"
                color="transparent"
                data-test="tooltip-trigger"
                kind="tertiary"
                size="icon"
              >
                <InfoOutline size={16} />
              </Button>
              <Tooltip className={hintStyles} data-hint="true" placement="top">
                {hint}
              </Tooltip>
            </TooltipTrigger>
          : null}
        </div>
        <div className={childrenRecipe({ orientation })}>{children}</div>
        {message}
      </RACRadioGroup>
    );
  },
);

RadioGroup.displayName = 'RadioGroup';
